<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 gap-0 md:gap-2 xl:gap-4 2xl:gap-6 mt-2">
      <div class="col col-span-2">
        <div class="min-w-min card p-4 rounded-xl bg-white mt-4" v-if="this.dataBaseUsageValueInPer < 100">
          <div class="text-text2 heading-3 p-2 pb-1 pt-0 font-semibold">Profile Status</div>
          <div class="text-gray4 heading-4 px-2">Your profile is {{dataBaseUsageValueInPer}}% completed</div>
          <div class="w-full" >
            <div class="progress-bar rounded-lg h-3">
              <div class="progress-bar-value rounded-lg" :style="`width:` +  dataBaseUsageValueInPer + `%;`"></div>
            </div>
          </div>
          <div class="mt-2">
            <div class="p-1">
              <div v-if="dataBaseUsageValueInPer === 70" class="text-primary heading-5"><i class="fa-solid fa-circle-check pr-2"></i>Add a Organization Address.</div>
              <span @click="$router.push({name: 'OrgDetail'})" v-else class="text-gray4 heading-5 cursor-pointer"><i class="fa-solid fa-circle-xmark pr-2"></i>Add a Organization Address.</span>
            </div>
            <div class="p-1">
              <div v-if="dataBaseUsageValueInPer === 60" class="text-primary heading-5"><i class="fa-solid fa-circle-check pr-2"></i>Add a Quotation/Invoice Address.</div>
              <span @click="$router.push({name: 'OrgDetail'})" v-else class="text-gray4 heading-5 cursor-pointer"><i class="fa-solid fa-circle-xmark pr-2 "></i>Add a Quotation/Invoice Address.</span>
            </div>
            <div class="text-right">
              <span @click="$router.push({name: 'OrgDetail'})" class="text-primary heading-5 p-1 cursor-pointer">Complete your Profile</span>
            </div>
          </div>
        </div>
        <div class="text-primary cursor-pointer" @click="$router.push({name: 'newdashboard'})">NewDashBoard</div>
        <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 ">
          <div style="height: 160px;" class="dashboardCard card" v-if="isRequestPermission" @click.stop="$router.push({name: 'request'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Request(s)
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalRequestCount }}</div>
                <div class="text-gray4 heading-6">Total</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.totalScheduleRequestToday }}</div>
                <div class="text-gray4 heading-6">Schedule Today</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-code-pull-request h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" v-if="isQuotePermission" @click.stop="$router.push({name: 'quotation'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition ">
                Quote(s)
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalOpenQuotesCount }}</div>
                <div class="text-gray4 heading-6">Open Quote</div>
                <div class="text-text2 font-semibold pt-2 ">{{dashboardObj.totalOpenQuotesAmount | amountFormaterWithToFix}}</div>
                <div class="text-gray4 heading-6">Total of Quotes</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fab fa-quora h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" v-if="isJobPermission" @click.stop="$router.push({name: 'job'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Job(s)
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalJobCount }}</div>
                <div class="text-gray4 heading-6">Jobs</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.unscheduledJobCount }}</div>
                <div class="text-gray4 heading-6">Unscheduled</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-briefcase h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" v-if="isInvoicePermission" @click.stop="$router.push({name: 'invoice'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Invoice(s)
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalInvoiceDueCount }}</div>
                <div class="text-gray4 heading-6">Invoice Due</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.totalInvoiceDueAmount | amountFormaterWithToFix }}</div>
                <div class="text-gray4 heading-6">Total Due</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-file-invoice-dollar h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" @click.stop="$router.push({name: 'payment'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Payment(s)
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalPaymentToday | amountFormaterWithToFix}}</div>
                <div class="text-gray4 heading-6">Received today</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.totalPaymentMTD | amountFormaterWithToFix }}</div>
                <div class="text-gray4 heading-6">Received MTD</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-dollar-sign h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" v-if="isExpPermission" @click.stop="$router.push({name: 'Expense'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Expense
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalExpenseToday | amountFormaterWithToFix }}</div>
                <div class="text-gray4 heading-6">Today Exp today</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.totalExpenseMTD | amountFormaterWithToFix }}</div>
                <div class="text-gray4 heading-6">Today Exp MTD</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-money-bill-transfer h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" v-if="isShiftPermission" @click.stop="$router.push({name: 'shift'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Shift
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalClockedIn }}</div>
                <div class="text-gray4 heading-6">Clocked In</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.totalBreakOn }}</div>
                <div class="text-gray4 heading-6">On Break</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-clock h-8 text-primary"></i></span>
            </div>
          </div>
          <div style="height: 160px;" class="dashboardCard card" v-if="isTaskPermission" @click.stop="$router.push({name: 'Task'})">
            <span class="text-right">
              <p class=" text-text2 heading-4 font-semibold group-hover:text-primary transition">
                Task
              </p>
              <div class="pt-2">
                <div class="text-text2 font-semibold">{{dashboardObj.totalTaskToday }}</div>
                <div class="text-gray4 heading-6">Today Task</div>
                <div class="text-text2 font-semibold pt-2">{{dashboardObj.totalPendingTask }}</div>
                <div class="text-gray4 heading-6">On Pending</div>
              </div>
            </span>
            <div class="card_icon dashboardIconCard">
              <span><i class="fa-solid fa-clipboard-list h-8 text-primary"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-span-1 mt-8 xl:mt-4 2xl:mt-4">
        <div class="card rounded-xl p-3 px-4 bg-white flex items-center justify-between heading-5 font-semibold mb-2 text-text2"  v-show="sortedList.length > 0">
          <div>Your Assignments</div>
          <div class="text-primary cursor-pointer" @click="$router.push({name: 'schedule'})">Schedule</div>
        </div>
        <div class="overflow-auto" :style="`max-height:` + cardHight + `px !important;min-height:` + cardHight + `px !important;`" v-show="sortedList.length > 0" v-if="isLoading">
          <div v-for="(details, index) in sortedList" :key="index" >
            <div class="heading-5 text-gray4 py-1 pt-2 pl-1" >
              <span @click.stop="openMap(index, details)" class="cursor-pointer hover:text-primary transition group">
                {{ details.secondTitle }}<i class="fa-solid fa-location-arrow pl-1 text-primary invisible group-hover:visible"></i>
              </span>
            </div>
            <div class="card rounded-xl p-2 bg-white flex hover:border-gray2  border border-transparent cursor-pointer transition" @click.stop="redirectOnDetails(details)">
              <div v-html="getIcons(details.entitySlug)"></div>
              <div class="text-gray4 heading-6 pl-2">
                <div class="flex items-center justify-between">
                  <p>
                    <span>{{setTimes(details.startDate, details.endDate)}}</span>
                  </p>
                  <span v-if="details.fromOffice" class="">At Office</span>
                </div>
                <div class="font-semibold">{{details.title}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="sortedList.length === 0" class="card rounded-xl p-2 bg-white flex" v-if="isLoading">
          <div class="text-gray4 heading-4 p-2"><i class="fa-regular fa-clipboard pr-2"></i>There is no data in your schedule list yet.</div>
        </div>
        <div v-if="!isLoading" class="card rounded-xl p-2 bg-white flex ">
          <div class="text-gray4 heading-4 p-2 flex">
            <div class="loader2 ease-linear mr-2"></div>
            Loading.....
          </div>
        </div>
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
   </div>
</template>
<script>
/* global google */
import {jsCoreDateCreator} from '@/utils/convertDateAndTime.js'
// import moment from 'moment'
import moment from 'moment'
import MyJobApp from '@/api/MyJobApp.js'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import {FilterPermissions} from '@/utils/Permissions.js'

export default {
  name: "landing-page",
  components: {
    Loader,
    snakBar,
  },
  data() {
    return {
      // temp: FilterPermissions(''),
      isRequestPermission: false,
      isQuotePermission: false,
      isJobPermission: false,
      isInvoicePermission: false,
      isExpPermission: false,
      isShiftPermission: false,
      isTaskPermission: false,
      dataBaseUsageValueInPer: 100,
      isLoading: false,
      cardHight: 0,
      sortedList: [],
      dashboardObj: {},
      scheduleList: [],
      toDateUtc: '',
      monthFirstDay: '',
      setUsedLocation: '',
      orgDetail: {},
      currentLocation: {
        latitude: 0,
        longitude: 0,
      }
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.isRequestPermission = FilterPermissions('request')
      this.isQuotePermission = FilterPermissions('quote')
      this.isJobPermission = FilterPermissions('job')
      this.isInvoicePermission = FilterPermissions('invoice')
      this.isExpPermission = FilterPermissions('expense')
      this.isShiftPermission = FilterPermissions('shift')
      this.isTaskPermission = FilterPermissions('task')
    }, 100);
    var date = new Date();
    let temp = new Date(date.getFullYear(), date.getMonth(), 1);
    this.monthFirstDay = new Date(temp).toUTCString();
    this.toDateUtc = new Date().toUTCString();
    this.cardHight = window.innerHeight - 161
    this.orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
    console.log('this.orgDetail', this.orgDetail)
    if (this.orgDetail !== null) {
      console.log('Organization address used for tracking');
      this.currentLocation.latitude = this.orgDetail?.latitude;
      this.currentLocation.longitude = this.orgDetail?.longitude;
      this.setUsedLocation = 'Office'
    } else {
      this.setUsedLocation = 'Your'
      this.getUserLocation();
      console.log('User location used for tracking');
    }
    this.getOrgDetail()
    this.getDashboardDetail()
    this.getScheduleListing()
  },
  beforeDestroy() {
  },
  methods: {
    openMap (index, data) {
      if (data.entitySlug === 'task') {
        let temp = FilterPermissions('task')
        if (temp) {
          this.redirectTomap(index)
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task location`})
        }
      }
      if (data.entitySlug === 'job') {
        let temp = FilterPermissions('job')
        if (temp) {
          this.redirectTomap(index)
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Job location`})
        }
      }
      if (data.entitySlug === 'request') {
        let temp = FilterPermissions('request')
        if (temp) {
          this.redirectTomap(index)
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request location`})
        }
      }
    },
    redirectTomap (index) {
      let lat1 = index === 0 ? this.currentLocation.latitude: this.scheduleList[index - 1].latitude
      let lng1 = index === 0 ? this.currentLocation.longitude : this.scheduleList[index - 1].longitude
      let lat2 = this.scheduleList[index].latitude
      let lng2 = this.scheduleList[index].longitude 
      let url = 'https://www.google.com/maps/dir/?api=1&origin=' + lat1 + ',' + lng1 + '&destination=' + lat2 + ',' + lng2
      window.open(url, '_blank')
    },
    getUserLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(currentPosition => {
          this.currentLocation.latitude = currentPosition.coords.latitude
          this.currentLocation.longitude = currentPosition.coords.latitude
        })
      }
    },
    getDashboardDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DashboardDetailApi(
        moment(new Date()).format('YYYY-MM-DD'),
        this.monthFirstDay,
        this.toDateUtc,
        response => {
          this.dashboardObj = response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    setTimes (start, end) {
      let displayText = '';
      const jsDate = new Date(start);
      const jeDate = new Date(end);

      if (
        jsDate.getHours() === 0 &&
        jsDate.getMinutes() === 0 &&
        jeDate.getHours() === 23 &&
        jeDate.getMinutes() === 59
      ) {
        displayText = ' All-Day';
      } else {
        displayText = ` ${moment(jsDate).format('hh:mm a')} - ${moment(
          jeDate,
        ).format('hh:mm a')}`;
      }

      return displayText;
    },
    async getScheduleListing () {
      this.isLoading = false
      this.scheduleList = []
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DashboardAssignmentListApi(
      moment(new Date()).format('YYYY-MM-DD'),
        response => {
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            const entityDetail = temp[index].entityDetail
            const details = {
              title: entityDetail.entityTitle ? entityDetail.entityTitle: `Request for ${entityDetail.customerName} `,
              id: temp[index].entitySlug === 'job' ? entityDetail.visitId : entityDetail.entityDetailId,
              startDate: entityDetail.startDate,
              endDate: entityDetail.endDate,
              entitySlug: temp[index].entitySlug,
              strtTime: new Date(entityDetail.startDate).getTime(),
              latitude: entityDetail.latitude === 0 ? this.orgDetail.latitude : entityDetail.latitude,
              longitude: entityDetail.longitude === 0 ? this.orgDetail.longitude : entityDetail.longitude,
            };
            this.scheduleList.push(details)
          }
          this.getTaskListing()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    async getTaskListing() {
      MyJobApp.DashboardTaskListApi(
        moment(new Date()).format('YYYY-MM-DD'),
        response => {
          let tempA = response.Data !== null ? response.Data : []
          for (let index = 0; index < tempA.length; index++) {
            let element = tempA[index]
            const sTime = moment(new Date(element.startTime)).format('HH:mm:ss');
            const eTime = moment(new Date(element.endTime)).format('HH:mm:ss');
            const startDate = jsCoreDateCreator(
              `${moment(new Date(element.taskDate)).format('YYYY-MM-DD')} ${sTime}`,
            );
            const endDate = jsCoreDateCreator(
              `${moment(new Date(element.taskDate)).format('YYYY-MM-DD')} ${eTime}`,
            );
            const details = {
              title: element.taskTitle,
              id: element.taskId,
              startDate,
              endDate,
              strtTime: new Date(startDate).getTime(),
              entitySlug: 'task',
              fromOffice: false,
              latitude: element.latitude === 0 ? this.orgDetail.latitude : element.latitude,
              longitude: element.longitude === 0 ? this.orgDetail.longitude : element.longitude,
            };
            if (element.latitude === 0 && element.longitude === 0) {
              details.fromOffice = true
            }
            this.scheduleList.push(details)
          }
          // this.sortedList = this.scheduleList
          setTimeout(() => {
            this.sortedAsc()
          }, 200);
          setTimeout(() => {
            this.sortedList = []
            let tempArr = []
              for (let index = 0; index < this.scheduleList.length; index++) {
                let temp1 = this.getDistanceUnit( this.calculateDistance(index === 0 ? this.currentLocation.latitude: this.scheduleList[index - 1].latitude, index === 0 ? this.currentLocation.longitude : this.scheduleList[index - 1].longitude, this.scheduleList[index].latitude, this.scheduleList[index].longitude, 'K',) * 1000,)
                let temp2 = ' approx'
                let temp3 = index === 0 ? ` from ${this.setUsedLocation} location` : ' in between'
                console.log('temp2', temp1, index)
                this.scheduleList[index].distanceTitle =  temp1 + temp2 + temp3
                console.log('this.scheduleList', this.scheduleList[index])
                let temp = this.getData(index === 0 ? this.currentLocation.latitude: this.scheduleList[index - 1].latitude, index === 0 ? this.currentLocation.longitude : this.scheduleList[index - 1].longitude, this.scheduleList[index].latitude, this.scheduleList[index].longitude, index)
                temp.then((a) => {
                  if (a.rows[0].elements[0].status === 'OK') {
                    this.sortedList[index].secondTitle = a.rows[0].elements[0].duration.text + ' drive (' + a.rows[0].elements[0].distance.text + ')' + temp3
                  } else {
                    this.sortedList[index].secondTitle = this.sortedList[index].distanceTitle
                  }
                })
                if (index <= 4) {
                  tempArr.push(this.scheduleList[index])
                }
              }
              this.sortedList = tempArr
              console.log('this.sortedList', this.sortedList)
              setTimeout(() => {
                this.isLoading = true
              }, 1000);
          }, 500);
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    async getData (lat1, lon1, lat2, lon2) {
      var p1 = new google.maps.LatLng(lat1, lon1);
      var p2 = new google.maps.LatLng(lat2, lon2);
      var service = new google.maps.DistanceMatrixService();
      return service.getDistanceMatrix({'origins': [p1], 'destinations': [p2], travelMode: google.maps.TravelMode.DRIVING}, this.callback)
    },
    callback (response, status) {
      console.log('apiDistance--statusstatusstusstatus---->',response , status)
    },
    async sortedAsc() {
      return this.scheduleList.sort((a, b) => {
          if (a.strtTime < b.strtTime) return -1;
          if (a.strtTime > b.strtTime) return 1;
          return 0;
      });
    },
    getIcons (data) {
      if (data === 'task') {
        return `<span class="text-gray4 heading-4"><i class="fa-solid fa-clipboard-list w-8"></i></span>`
      } else if (data === 'job') {
        return `<span class="text-gray4 heading-4"><i class="fa-solid fa-briefcase w-8"></i></span>`
      } else if (data === 'request') {
        return `<span class="text-gray4 heading-4"><i class="fa-solid fa-code-pull-request w-8"></i></span>`
      }
    },
    getDistanceUnit (distanceMeter) {
      console.log('distanceMeter', distanceMeter / 1000 >= 1000)
      let distance = '';
      if (!isNaN(distanceMeter)) {
        if (navigator.languages != undefined) {
          console.log('navigator.languages', navigator.languages)
          const countryCode = navigator.languages[0];
          switch (countryCode) {
            case 'US':
              if (distanceMeter * 0.00062137 >= 1000) {
                distance = `999+ mi`;
              } else {
                distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
              }
              break;
            default:
              if (distanceMeter / 1000 >= 1000) {
                distance = `999+ km`;
              } else {
                distance = `${(distanceMeter / 1000).toFixed(1)} km`;
              }
              console.log('distance12 3', distance)
            break;
          }
          return distance;
        } else {
          if (distanceMeter / 1000 >= 1000) {
            distance = `999+ km`;
          } else {
            distance = `${(distanceMeter / 1000).toFixed(1)} km`;
          }
          return distance;
        }
      } else {
        return '--'
      }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      console.log('lat1', lat1)
      console.log('lon1', lon1)
      console.log('lat2', lat2)
      console.log('lon2', lon2)
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;

      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
    redirectOnDetails (item) {
      if (item.entitySlug === 'task') {
        let temp = FilterPermissions('task')
        if (temp) {
          let date = moment(new Date(item.startDate)).format('YYYY-MM-DD')
          this.$router.push({name: 'TaskDetail', params: {taskId: item.id} , query: {date: date}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task detail`})
        }
      }
      if (item.entitySlug === 'job') {
        let temp = FilterPermissions('job')
        if (temp) {
          this.$router.push({name: 'visitDetail', params: {visitId: item.id}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Job detail`})
        }
      }
      if (item.entitySlug === 'request') {
        let temp = FilterPermissions('request')
        if (temp) {
          this.$router.push({name: 'requestDetail', params: {requestId: item.id}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request detail`})
        }
      }
    },
    getOrgDetail() {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      MyJobApp.getOrgDetail(
        (response) => {
          let orgDetail = response.Data
          let percompleted = 30;
          if (orgDetail.latitude !== 0) {
            percompleted += 40;
          }
          if (orgDetail.addressLine1 !== '') {
            percompleted += 30;
          }
          console.log('percompleted', percompleted)
          this.dataBaseUsageValueInPer = percompleted
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  }
}
</script>

<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
    position: absolute;
    left: 20px;
    top: -25px;
    width: 80px!important;
    height: 80px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1295BA;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.progress-bar {
  background-color: rgba(156, 156, 156, 0.691);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  height: 100%;
  background-color: rgb(18,149,186);
  transform-origin: 0% 50%;
}

</style>
